import { setAPI } from "./api";
import { auth } from "../firebase";
import _ from "lodash";
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from "../utils/messageServices/messageService";
import { displayErrorModal } from "../utils/messageServices/modalService";
import { axiosInstance } from "../axios/axiosInstance";

const API_URL = setAPI();


//Writing
export async function setAxiosData(id, data, multipleValue, multipleSelected) {
  console.log("Multiple Selected: ", multipleSelected, "Multiple Value: ", multipleValue)
  try {
    let task = null;
    if(multipleSelected) {
      console.log("Creating multiple tasks")
      task = await axiosInstance.post(`${API_URL}/api/db/mo/create?multipleSelected=${multipleSelected}&multipleValue=${multipleValue}`, { mongoId: id, data: data });
    } else {
      task = await axiosInstance.post(`${API_URL}/api/db/mo/create`, { mongoId: id, data: data });
    }

    if (task.status === 201) {
      displaySuccessMessage("Vorgang erfolgreich!", "Daten wurden erfolgreich gespeichert.")
      return true;
    };

    //Es werden daten nach einem vorgegebenen Schema erstellt, die konfiguration im backend ist nicht abgeschlossen.
  } catch (error) {
    if (error.response.status === 405) {
      displayErrorMessage("Prozessfehler!", `Informationen: ${error.message} ${error.response.data.message}`)
      displayErrorModal("Prozessfehler!", error?.response?.data?.error, error?.response?.data?.stack)
    } else {
      displayErrorMessage("Fehler beim Speichern!", `Daten konnten nicht gespeichert werden.`);
      displayErrorModal("Fehler beim Speichern!", error?.response?.data?.error, error?.response?.data?.stack)
    };
    console.log(error);
    return null;
  };
};

export async function getProject(id) {
  try {
    return await axiosInstance.post(`${API_URL}/api/db/projects/get`, { projectId: id})
  } catch (error) {
    console.log(error)
    displayErrorModal("Fehler beim Abrufen des Projekts", error?.response?.data?.error, error?.response?.data?.stack)
  }
};

export async function getAllProjects(mongoRefFirebase) {
  try {
    return await axiosInstance.post(`${API_URL}/api/db/projects/getall`,{ mongoRefFirebase: mongoRefFirebase } )
  } catch (error) {
    console.log(error)
    displayErrorModal("Fehler beim Abrufen der Projekte", error?.response?.data?.error, error?.response?.data?.stack)
  }
};

export async function modifyTasks(option, data) {
  try {
    if (_.isEmpty(option) || _.isEmpty(data)) {
      throw new Error("Option or data is missing for this operation.")
    }
    const token = await auth.currentUser.getIdToken()
    return await axiosInstance.post(`${API_URL}/api/db/projects/task`, { data: { option: option, task: data }});
  } catch (error) {
    console.log("Failing while perform operation: ",error)
    return error
  };
}

export async function initProjectSchema(mongoId, projectIdName) {
  try {
    if (_.isEmpty(mongoId)) {
      throw new Error("Can not perform operation without an id.")
    }

    return await axiosInstance.post(`${API_URL}/api/db/schema/schema/sync/${projectIdName}`, { mongoId: mongoId });
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Initialisieren des Projektschemas", error?.response?.data?.error, error?.response?.data?.stack)
  };
};

export async function initCsvSchema(mongoId, projectIdName, user) {
  try {
    if (_.isEmpty(mongoId)) {
      throw new Error("Can not perform operation without an id.")
    }

    return await axiosInstance.post(`${API_URL}/api/db/schema/schema/sync/csv/${projectIdName}`, { mongoId: mongoId, user: user });
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Initialisieren des CSV-Schemas", error?.response?.data?.error, error?.response?.data?.stack)
  };
};

export async function getCustomFields(mongoId) {
  try {
    if (_.isEmpty(mongoId)) {
      throw new Error("Can not perform operation without an id.")
    }

    return await axiosInstance.post(`${API_URL}/api/db/mo/stream/fields`, { mongoId: mongoId });
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Abrufen der benutzerdefinierten Felder", error?.response?.data?.error, error?.response?.data?.stack)
  };
};


export async function createCustomField(mongoId, data) {
  try {
    if (_.isEmpty(mongoId)) {
      throw new Error("Can not perform operation without an id.")
    }

    return await axiosInstance.post(`${API_URL}/api/db/schema/projects/schemas/create`, { mongoId: mongoId, newField: data });
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Erstellen des benutzerdefinierten Feldes", error?.response?.data?.error, error?.response?.data?.stack)
  };
}

export async function addUserToProject(projectId, userId) {
  const token = await auth.currentUser.getIdToken()
  .then((token) => {
    return token;
  })
  .catch((err) => {
    console.log(err)
  });

  try {
    if (_.isEmpty(projectId)) {
      throw new Error("Can not perform operation without an mongoid.")
    }
    if (_.isEmpty(userId)) {
      throw new Error("Can not perform operation without an uid.")
    }
    if (_.isEmpty(token)) {
      throw new Error("Token is not valid.")
    }

    return await axiosInstance.post(`${API_URL}/api/db/projects/add_user/${userId}/${projectId}`, { data: null });

  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Hinzufügen des Benutzers", error?.response?.data?.error, error?.response?.data?.stack)
    return
  }
};

export async function getAllUsers() {
  try {
    return axiosInstance.post(`${API_URL}/api/users/v2/allUsers`)
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Abrufen der Benutzer", error?.response?.data?.error, error?.response?.data?.stack)
    return null;
  };
};

export async function reqCsvExport(start, end, mongoDBId) {
  try {
    return axiosInstance.post(`${API_URL}/api/invoice/invoice_dataset/${start}/${end}`, { _id: mongoDBId })
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Exportieren der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    return null;
  };
};

export async function reqXlsxExport(start, end, mongoDBId) {
  try {
    return axiosInstance.post(`${API_URL}/api/invoice/invoice_dataset/xlsx/${start}/${end}`, { _id: mongoDBId })
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Exportieren der Daten", error?.response?.data?.error, error?.response?.data?.stack)
    return null;
  };
};

/**
* 
* @param {ObjectId} userId - required
* @param {ObjectId} projectId - required
* @param {Object} invoiceDetails - required
* @returns Buffer as Downloadable File
* @description - This function is used to create a new invoice for a project. The user_id is required to identify the user who is creating the invoice. The projectId is required to identify the project for which the invoice is created. The invoiceDetails are required to create the invoice.
*/
export async function invoicing_create(projectId, user_id, invoiceDetails) {
  if (user_id === undefined) {
      displayWarningMessage("Es ist ein Fehler aufgetreten...😱", "Wir haben eine ungültige Anfrage erhalten. Bitte versuche es erneut. Sollte der Fehler weiterhin bestehen, kontaktiere bitte den Support.")
      return;
  };

  try {

      const data = await axiosInstance.post(`${API_URL}/api/invoice/pdf/${user_id}/${projectId}`, { configuration: invoiceDetails })
          .then((result) => {
              console.log(result.data);
              return result.data
          })
          .catch((err) => {
              console.log(err);
          });

      return data;
  } catch (error) {
      console.log(error.message);
      displayErrorModal("Fehler beim Erstellen der Rechnung", error?.response?.data?.error, error?.response?.data?.stack)
      displayErrorMessage("Es ist ein Fehler aufgetreten...😱", `Wir konnten die Aufgabe nicht korrekt an den Server übermitteln oder haben eine unerwartete Antwort erhalten. Bitte versuche es erneut. Fehler: ${error.message}`)
      return [];
  }
};

export async function getMissingProjectUsers(allowedUserId, projectId) {
  try {
    if (_.isEmpty(allowedUserId)) {
      throw new Error("Missing Users Array in function")
    }

    if (_.isEmpty(projectId)) {
      throw new Error("Missing Project-Id")
    }

    return axiosInstance.post(`${API_URL}/api/users/v2/allUsers/${projectId}`, { data: allowedUserId })
  } catch (error) {
    console.log(error);
    displayErrorModal("Fehler beim Abrufen der Benutzer", error?.response?.data?.error, error?.response?.data?.stack)
    return null;
  }
}