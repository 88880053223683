import { Descriptions, Tag, Timeline, Typography } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons';
import React from "react";

const { Text } = Typography;

const containerStyle = {
  display: 'flex',
  height: '100vh',
};

const leftStyle = {
  flex: '0 0 30%',
  textAlign: 'left',
  marginTop: '50px',
  padding: '10px',
};

const rightStyle = {
  flex: '0 0 70%',
};

export function ChangelogPage() {

    return (
        <div
        style={containerStyle}
        // style={{
        //   alignContent: "justify",
        //   textAlign: "justify",
        //   display: "block",
        // }}
        >
          <div style={leftStyle}>
          <Timeline
          >
            <Timeline.Item color="gray" dot={<ClockCircleOutlined className="timeline-clock-icon" />}>Version 1.0.3 / Refactoring Backend</Timeline.Item>
            <Timeline.Item color="gray" dot={<ClockCircleOutlined className="timeline-clock-icon" />}>Version 1.0.2 / 2-Faktor Auth, weiteres...</Timeline.Item>
            <Timeline.Item color="green" dot={<ClockCircleOutlined />}>Version 1.0.2 / Einzelkostenbuchungen, Automatische Berechnung unproduktive Zeiten, Übersicht aktive Verträge, PowerBi</Timeline.Item>
            <Timeline.Item color="black">Version 1.0.1 / (akuell: 9dc36b3a54e79877eb3face9fd8e6b49f02babc3) siehe Versionshinweise</Timeline.Item>
            <Timeline.Item>Version 1.0.0 / (veraltet)</Timeline.Item>
            <Timeline.Item>Version 0.9.9 / (veraltet)</Timeline.Item>
            <Timeline.Item>Version 0.9.8b / (veraltet)</Timeline.Item>
            <Timeline.Item>Version 0.9.8a / (veraltet)</Timeline.Item>
          </Timeline>
          </div>

          <div style={rightStyle}>
          <Typography.Title level={5}>
            Versionshinweise
          </Typography.Title>

            <Descriptions column={1} bordered>
              <Descriptions.Item label="Aktuelle Version">1.0.1 / SHA 9dc36b3a54e79877eb3face9fd8e6b49f02babc3 / 30.07.2024</Descriptions.Item>
              {/* Git änderungen */}
              <Descriptions.Item label="Änderungen">
                <ul>
                  <li>KPI-Berechnung: Berechnungen im Backend wurden angepasst und aktualisiert <Tag color="green">abgeschlossen</Tag></li>
                  <li>Kosten Management: Kosten können nun über die OPOS-Liste hinzugefügt werden, an einer Funktion zur Einzelbuchung von Kosten wird gearbeitet <Tag color="green">abgeschlossen</Tag></li>
                  <li>Verbesserung der Sicherheitsfeatures: Implementierung von Sitzungsbeschränkungen, Vorbereitungen für HELMET und CSURF Sicherung, eine Beschränkung für Anfragen wurde  hinterlegt <Tag color="orange">partly solved - PR:H/FE:S/P:AZU</Tag></li>
                  <li>Integration von reCAPTCHAv3 ENTERPRISE <Tag color="green">abgeschlossen</Tag></li>
                  <li>CRON-Job Planung im Kostenmanagement: Doppelbuchungen wird mit einem "Hot-Fix" entgegengewirkt, alle 12h wird nach Duplikaten gesucht und diese werden entfernt<Tag color="green">abgeschlossen</Tag></li>
                  <li>Verbesserung der Performance in der Sitzungserstellung <Tag color="green">abgeschlossen</Tag></li>
                  <li>Artikelverwaltung: Die Möglichkeit zum Import einer Artikelliste im CSV-Format wurde hinzugefügt <Tag color="green">abgeschlossen</Tag></li>
                  <li>Verbesserung der Benutzeroberfläche: Die Benutzeroberfläche wurde überarbeitet und Fehler behoben <Tag color="green">abgeschlossen</Tag></li>
                  <li>My Business: MyBusiness-Page wurde hinzugefügt, diese Seite ruft alle KPIs von allen Projekten ab <Tag color="orange">reopened</Tag></li>
                  <li>Projekt-KPIs: Entfernt wurde die Einzelübersicht über Vorgänge, durch einen Fehlerhaften Commit. Re-Enrollment läuft bereits. <Tag color="orange">reopened</Tag></li>
                  <li>Überarbeitung der Projektnavigation <Tag color="green">abgeschlossen</Tag></li>
                </ul>
              </Descriptions.Item>
              <Descriptions.Item label="Demnächst v.1.1.0">
                <ul>
                <li>Verbesserung der Sicherheitsfeatures: 2-Faktor-Auth, AXIOS-ROUTER-INSTANCES pro Funktion <Tag color="orange">partly solved - PR:H/FE:S/P:AZU</Tag></li>
                
                  <li>Hinzufügen der Logging Features für Sicherheitsprotokolle <Tag color="orange">partly solved - PR:H/FE:S/P:MONG</Tag></li>
                  <li>Kostenmanagement: Hinzufügen von Einzelkosten für das Projekt <Tag color="gray">Prio 1</Tag></li>
                  <li>Abrechnungsmanagement: (Tageweise Buchung von Personen) - Unbekannt, wann dieses Feature kommt</li>
                  <li>2-Faktor Authentifizierung mit Telefonnummer/Email</li>
                  <li>Integration von Cloud-Functions (Backend) für alle CRON-Jobs</li>
                  <li>Ansichten: Neue Aufgabenansicht (Detailansicht) für erstellte Vorgänge, Nachträgliches Bearbeiten von erstellten Vorgängen <Tag color="gray">Prio 1</Tag></li>
                  <li>UI Überarbeitung (Rendering Fehler)</li>
                  <li>Persönlicher Dateispeicher: Verschoben auf Unbestimmte Zeit</li>
                </ul>
              </Descriptions.Item>
            </Descriptions>
          </div>
          </div>
    );
    };