import axios from "axios";
import { setAPI } from "./api";
import { auth, db } from "../firebase";
import { displayErrorMessage, displaySuccessMessage } from "../utils/messageServices/messageService";
import { collection, where, query, getDocs, limit } from "firebase/firestore";

const API_URL = setAPI();

export async function crudUser(action, uid) {
    //deactivate, delete, activate, kick, kick-lock
    const dbTask = {
        task: action
    };

    try {
        const token = await auth.currentUser.getIdToken();
        const result = await axios.post(`${API_URL}/api/users/user/${uid}`, dbTask, { headers: { Authorization: `Bearer ${token}` } });
        displaySuccessMessage(result.data.message);
    } catch (error) {
        displayErrorMessage(error.response.data.message);
        console.log(error);
    };
};

export async function resetUserPassword(uid) {
    const firId = {
        firId: ""
    };

    try {
        const dbRef = collection(db, "users");
        const q = query(dbRef, where("mongoRef", "==", uid), limit(1));
        const querySnapshot = await getDocs(q);
        const ids = [];
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            ids.push(doc.id);
        });
        const documentId = ids[0];
        console.log(documentId);
        firId.firId = documentId;
    } catch (error) {
        console.log(error);
    };

    try {
        const token = await auth.currentUser.getIdToken();
        const result = await axios.post(`${API_URL}/register-login/reset-password`, firId, { headers: { Authorization: `Bearer ${token}` } });
        displaySuccessMessage(result.data.message);
    } catch (error) {
        displayErrorMessage(error.response.data.message);
        console.log(error);
    };
};

export async function sendServiceNotice(data, cronQuery, uid) {
    const { downtimeStart, downtimeEnd, downtimeReason, latestLogout } = data;

    try {
        const token = await auth.currentUser.getIdToken();
        return await axios.post(`${API_URL}/v1/admin_api/system/downtime?downtimeStart=${downtimeStart}&downtimeEnd=${downtimeEnd}&downtimeReason=${downtimeReason}&latestLogout=${latestLogout}&uid=${uid}&type=${process.env.REACT_APP_CRON_SON_ENV}${cronQuery}`,
            data, { headers: { Authorization: `Bearer ${token}` } })
    } catch (error) {
        console.log(error)
    };
};

export async function calculateCosts(data) {
    const { shiftNumber, cronId } = data;

    try {
        const token = await auth.currentUser.getIdToken();
        const result = await axios.post(`${API_URL}/v1/admin_api/accounting/calculate?shiftNumber=${shiftNumber}&cronId=${cronId}`,
            data, { headers: { Authorization: `Bearer ${token}` } });

        displaySuccessMessage("Vorgang erfolgreich", result.data.message);
        return true;
    } catch (error) {
        displayErrorMessage("Vorgang fehlgeschlagen", error.response.data.message);
        console.log(error)
        return null;
    };
};

export async function route_removeDuplicateCosts() {
    try {
        const token = await auth.currentUser.getIdToken();
        return await axios.post(`${API_URL}/v1/admin_api/calculatedProjectCosts/action/delete-duplicates`,
            { data: null },
            { headers: { Authorization: `Bearer ${token}` } })
    } catch (error) {
        console.log(error)
    };

}

export async function manageAbsences(data, absenceQuery, _id) {
    if (_id === undefined || _id === '') return console.log("No ID");

    try {
        const token = await auth.currentUser.getIdToken();
        return await axios.post(`${API_URL}/api/users/v2/absences/${_id}?${absenceQuery}`,
            data,
            { headers: { Authorization: `Bearer ${token}` } })
    } catch (error) {
        console.log(error)
    };
}

export async function getUserStatistics(data, hasFilter, filter, _id) {
    if (_id === undefined || _id === '') return console.log("No ID");

    try {
        const token = await auth.currentUser.getIdToken();
        return await axios.post(`${API_URL}/api/users/statistics/${_id}?filter=${hasFilter}${filter}`,
            data,
            { headers: { Authorization: `Bearer ${token}` } })
    } catch (error) {
        console.log(error)
    };
}


//Gets the User from MongoDB
export async function getUserByFirId(FirebaseId) {
    try {
        //const token = await auth.currentUser.getIdToken(); , { headers: { Authorization: `Bearer ${token}` }}
        return await axios.post(`${API_URL}/api/users/v2/${FirebaseId}`)
    } catch (error) {
        console.log(error)
    };
};

// export async function fetchSpecifiedUsers(data) {
//     try {
//         const token = await auth.currentUser.getIdToken();
//         return await axios.post(`${API_URL}/api/users/users`, { manager: data },{ headers: { Authorization: `Bearer ${token}` }})
//     } catch (error) {
//         console.log(error)
//     };
// };