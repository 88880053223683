//eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Button, Descriptions, InputNumber, Select, Space, Table, Tag } from "antd";
import { Container } from "@mui/system";
import AddUserDialog from "../../../global/dialogs/AddUserDialog";
import { fetchAllUsersWith } from "../../../../routing/userRouting";
import store from "../../../../redux/store";
import _ from "lodash";
import ServiceNoticeDialog from "../../../global/dialogs/ServiceNoticeDialog";
import { AddUserModal } from "../../../modals/AddUserModal";
import { calculateCosts, route_removeDuplicateCosts } from "../../../../routing/userManagement";
import SimpleBackdrop from "../../../../components/global/LoadingBackdrop"
import { ReAuthenticationFormModal } from "../../../security/ReAuthenticationForm.modal";
import { v4_change_user_role } from "../../../../routing/v4_Routes/user.routes";
import Search from "antd/es/input/Search";
import { displayErrorModal, displayInfoModal, displaySuccessModal, displayWarningModal } from "../../../../utils/messageServices/modalService";
import { v4_test_route_error } from "../../../../routing/v4_Routes/test.routes";

function AdminPage() { 

    const [userDialog, setUserDialog] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userSearchValue, setUserSearchValue] = useState("");
    //eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [serviceNoticeDialogOpen, setServiceNoticeDialogOpen] = useState(false);
    const [shiftNumber, setShiftNumber] = useState(0);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [currValue, setCurrValue] = useState(null);
    const [currId, setCurrId] = useState(null);

    const handleFetchAllUsers = async () => {
        setIsLoading(true);
        await fetchAllUsersWith(store.getState().userState.firId)
            .then((result) => {
                console.log(result.data.users)
                setUsers(result.data.users);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCalculation = async () => {
        setIsLoading(true);
        await calculateCosts({ shiftNumber: shiftNumber, cronId: `CRON_${Date.now()}_FED_MANUAL_CALC` })
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const removeDuplicateCosts = async () => {
        setIsLoading(true);
        await route_removeDuplicateCosts()
            .then((result) => {
                console.log(result);
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onSearch = () => {
        if (userSearchValue === "") {
            setFilteredUsers(users);
            return;
        };
        setFilteredUsers(_.filter(users, (task) => {
            const { firstName, lastName, _id, email, userName, role, timeBoardId, sharePointId } = task;
            const searchValueLower = _.lowerCase(userSearchValue);
            return (
                _.lowerCase(firstName).includes(searchValueLower) ||
                _.lowerCase(lastName).includes(searchValueLower) ||
                _.lowerCase(email).includes(searchValueLower) ||
                _.lowerCase(_id).includes(searchValueLower) ||
                _.lowerCase(userName).includes(searchValueLower) ||
                _.lowerCase(role).includes(searchValueLower) ||
                _.lowerCase(timeBoardId)?.includes(searchValueLower) ||
                _.lowerCase(sharePointId)?.includes(searchValueLower)
            );
        }));
    };

    useEffect(() => {
        onSearch();
    }, [userSearchValue]);


    const displayTestError = (errType = "error") => {

        switch (errType) {
            case "error":
                displayErrorModal("Test Error", "This is a test error message.");
                break;
            case "success":
                displaySuccessModal("Test Success", "This is a test success message.");
                break;
            case "warning":
                displayWarningModal("Test Warning", "This is a test warning message.");
                break;
            case "info":
                displayInfoModal("Test Info", "This is a test info message.");
                break;
            default:
                console.log("No error type specified.");
                break;
        }

    };

    const loadingBackdropTest = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    };

    async function testRouteError() {
        setIsLoading(true);
        const response = await v4_test_route_error();
        console.log(response);
        setIsLoading(false);
    }
        

    return (
        <>
            <Container sx={{ mt: 5 }}>
                <Descriptions title="Allgemein" bordered>
                    <Descriptions.Item label="Sitzung">Sitzungs-Id</Descriptions.Item>
                    <Descriptions.Item label="Web Version">Version</Descriptions.Item>
                    <Descriptions.Item label="Umgebung">Development</Descriptions.Item>
                    <Descriptions.Item label="Letzte Commit">2018-04-24 18:00:00</Descriptions.Item>
                    <Descriptions.Item label="Commit Nummer" span={2}>
                        Unbekannt
                    </Descriptions.Item>
                </Descriptions>

                <Descriptions title="Einstellungen" bordered style={{ marginTop: 20, textAlign: "left" }} >
                    <Descriptions.Item label="User">
                        <Button type="link" onClick={() => { setUserDialog(true) }}>
                            Nutzer anlegen
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="User">
                        <Button type="link" onClick={handleFetchAllUsers}>
                            Alle Nutzer abrufen
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Web Version" span={2}>{navigator.appVersion}</Descriptions.Item>
                    <Descriptions.Item label="Umgebung">YES</Descriptions.Item>
                </Descriptions>

                <Descriptions title="Testfunktionen" bordered style={{ marginTop: 20, textAlign: "left" }} >
                    <Descriptions.Item label="Error Modal">
                        <Button type="link" onClick={() => { displayTestError("error") }}>
                            Test Fehlermeldung global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Success Modal">
                        <Button type="link" onClick={() => { displayTestError("success") }}>
                            Test Erfolgsmeldung global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Warning Modal">
                        <Button type="link" onClick={() => { displayTestError("warning") }}>
                            Test Warnung global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Info Modal">
                        <Button type="link" onClick={() => { displayTestError("info") }}>
                            Test Info global
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Loading Backdrop">
                        <Button type="link" onClick={loadingBackdropTest}>
                            Test Ladebildschirm
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="Test Route Error">
                        <Button type="link" onClick={testRouteError} loading={isLoading}>
                            Test Route Error (Async)
                        </Button>
                    </Descriptions.Item>
                </Descriptions>

                <Descriptions title="Aktionen" bordered style={{ marginTop: 20, textAlign: "left" }} >
                    <Descriptions.Item label="Kostenberechnung anstoßen" span={6}>
                        <Space>
                            <Button type="link" onClick={handleCalculation} danger>
                                Kostenberechnung anstoßen
                            </Button>
                            <InputNumber type="number" placeholder="Anzahl der Tagesversätze" onChange={
                                (value) => {
                                    setShiftNumber(value);
                                } 
                            } />
                        </Space>
                    </Descriptions.Item>

                    <Descriptions.Item label="Kosten Duplikate entfernen" span={6}>
                        <Space>
                            <Button type="link" onClick={removeDuplicateCosts} danger>
                                Duplikate entfernen
                            </Button>
                        </Space>
                    </Descriptions.Item>

                    <Descriptions.Item label="Service Offline Notice" span={6}>
                        <Button type="link" onClick={() => { setServiceNoticeDialogOpen(true) }} danger>
                            SON Konfigurieren
                        </Button>
                    </Descriptions.Item>

                    <Descriptions.Item label="Azure" span={6}>
                        <Button type="link" onClick={() => { setUserDialog(true) }}>
                            Manage Azure Network
                        </Button>
                    </Descriptions.Item>

                    <Descriptions.Item label="Firebase" span={6}>
                        <Button type="link" onClick={() => { setUserDialog(true) }}>
                            Manage Firebase
                        </Button>
                    </Descriptions.Item>

                </Descriptions>

                
                {!_.isEmpty(users) && (
                    <div style={{
                        marginTop: '30px'
                    }}>
                    <Search placeholder="Suche nach Nutzern" size="large"
                        onSearch={(value) => {
                            setUserSearchValue(value);
                        }}
                        onChange={(e) => {
                            setUserSearchValue(e.target.value);
                        }}
                        allowClear
                    />
                    <Table
                        style={{
                            marginTop: '30px'
                        }}
                        columns={[{
                            title: 'First Name',
                            dataIndex: 'firstName',
                            key: 'firstName',
                        },
                        {
                            title: 'Last Name',
                            dataIndex: 'lastName',
                            key: 'lastName',
                        },
                        {
                            title: 'Firebase Id',
                            dataIndex: 'userName',
                            key: 'userName',
                        },
                        {
                            title: 'Berechtigungen',
                            key: 'action',
                            render: (_, record) => (
                                <Space size={"middle"}>
                                    <Select
                                        defaultValue={record?.role || "employee"}
                                        style={{ width: 120 }}
                                        onChange={(value) => {
                                            setCurrValue(value);
                                            setCurrId(record?._id || null);
                                            setConfirmModalOpen(true);
                                        }}
                                    >
                                        <Select.Option value="administrator">Admin<Tag color="red">Erweiterte Berechtigungen</Tag></Select.Option>
                                        <Select.Option value="employee">Standard User (Projekt-MA)</Select.Option>
                                        <Select.Option value="management">Manager</Select.Option>
                                        <Select.Option value="accounting">Buchhaltung/Finanzen</Select.Option>
                                        <Select.Option value="projectLead">Projektleiter</Select.Option>
                                        <Select.Option value="consultant">Projektbetreuer</Select.Option>
                                        <Select.Option value="hr">Human Ressources</Select.Option>
                                        <Select.Option value="viewer">Ansicht</Select.Option>
                                        <Select.Option value="project-assistance">Projektassistenz</Select.Option>
                                    </Select>
                                </Space>
                            )
                        }]}
                        dataSource={filteredUsers || users}
                    />
                    </div>
                )}


            </Container>

            <AddUserModal DetailedUserTypes={true} Admin={true} OpenDialog={userDialog} setOpen={() => { setUserDialog(false) }} />
            <ServiceNoticeDialog OpenDialog={serviceNoticeDialogOpen} setOpen={() => { setServiceNoticeDialogOpen(false) }} />
            {isLoading && <div>
                <SimpleBackdrop open={isLoading} />
            </div>}
            <ReAuthenticationFormModal Open={confirmModalOpen} OnCancel={() => {
                setConfirmModalOpen(false);
                setCurrId(null);
                setCurrValue(null);
            }}
                OnSuccess={async () => {
                    setIsLoading(true);
                    await v4_change_user_role(currId, currValue)
                    setIsLoading(false);
                    setCurrId(null);
                    setCurrValue(null);
                }}
            />
        </>
    );
};


export default AdminPage;