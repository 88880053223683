import axios from "axios";
import { auth } from "../../firebase";
import { v4_setAPI } from "../api";
import { displaySuccessNotification } from "../../utils/messageServices/notificationService";
import _ from "lodash";
import { displayErrorModal } from "../../utils/messageServices/modalService";

const API_URL = v4_setAPI();


/**
 * 
 * @param {File} file 
 * @returns 
 */
export async function v4_upload_article_list(file, _id) {
    try {
        let response = null;
        const token = await auth.currentUser.getIdToken();
        const formData = new FormData();
        formData.append("file", file);
        response = await axios.post(`${API_URL}/articles/${_id}/import/articles`, formData, { headers: { Authorization: `Bearer ${token}` } });

        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
        displayErrorModal("Fehler beim Laden der Daten", error?.response?.data?.error, error?.response?.data?.stack)
        return null;
    };
};