import { Alert, Button, Image, Input, Modal, Space, Spin, Table } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { route_readArticles } from "../../../routing/v3_Routes/v3_Routes";
import { displayErrorMessage } from "../../../utils/messageServices/messageService";
import { fs_checkAndGetArticleImage, fs_checkIfArticleImageExists } from "../../../firebase/fs_defaultActions";


function SelectTaskModal(props) {

  const taskData = useSelector((state) => state.projectState.selectedProject.tasks);
  const selectedProject = useSelector((state) => state.projectState.selectedProject);
  const articles = useSelector((state) => state.projectState.selectedProject.selectedProjectArticles);

  //Search Task
  const [searchValue, setSearchValue] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(articles);
  const [loadAll, setLoadAll] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(false);

  const handleSelectTask = (task) => {
    console.log(task);
    props.onSelectTask(task);
  }

  const handleNewTask = () => {
    console.log("new task");
    props.onNewTask();
  }

  const handleSearch = (value) => {
    setSearchValue(value);
  }

  const handleLargeDatasetSearch = async () => {
    setLoading(true);
    await fetchTasks();
    setLoading(false);
  };

  const fetchTasks = async () => {
    if (selectedProject.__v === 1) {
      if (selectedProject.articleSetIsLarge === true) {
        if (loadAll === true) {
          await route_readArticles(selectedProject._id, true);
        } else {
          await route_readArticles(selectedProject._id, loadAll, searchValue);
        }
      } else {
        await route_readArticles(selectedProject._id);
      }
    } else {
      displayErrorMessage("Kontaktieren Sie den Support!", "Diese Funktion ist nur für Projekte der Version 1 verfügbar. Sie verwenden derzeit die Version 0.");
    }
  };

  const preFetchTask = async () => {
    if (selectedProject.__v === 1) {
      if (selectedProject.articleSetIsLarge === true) {
        await route_readArticles(selectedProject._id, false);
      } else {
        await route_readArticles(selectedProject._id);
      }
    } else {
      displayErrorMessage("Kontaktieren Sie den Support!", "Diese Funktion ist nur für Projekte der Version 1 verfügbar. Sie verwenden derzeit die Version 0.");
    }
  };

  useEffect(() => {
    let count = 0;
    if (count === 0) {
      preFetchTask();
      count++;
    };

    return () => {
      count = 0;
    };

  }, []);

  React.useEffect(() => {
    if (selectedProject.articleSetIsLarge === true && loadAll === false) {
      setFilteredData(articles);
    } else if (selectedProject.articleSetIsLarge === true && loadAll === true) {
      setFilteredData(_.filter(articles, (task) => {
        const { title, description, id, uniqueArticleNumber } = task;
        const searchValueLower = searchValue.toLowerCase();
        return (
          title.toLowerCase().includes(searchValueLower) ||
          (description?.toLowerCase().includes(searchValueLower)) ||
          uniqueArticleNumber.includes(searchValueLower) ||
          id.includes(searchValueLower)
        );
      }));
    } else {
      setFilteredData(_.filter(articles, (task) => {
        const { title, description, id, uniqueArticleNumber } = task;
        const searchValueLower = searchValue?.toLowerCase();
        return (
          title?.toLowerCase()?.includes(searchValueLower) ||
          (description?.toLowerCase()?.includes(searchValueLower)) ||
          uniqueArticleNumber?.includes(searchValueLower) ||
          id?.includes(searchValueLower)
        );
      }));
    }

  }, [searchValue, articles, loadAll]);

  return (
    <Modal
      title="Aufgabe oder Artikel auswählen"
      open={props.open}
      closable={true}
      onCancel={props.onCancel}
      footer={null}
      width={"80%"}
      style={{
        top: "5vh",

      }}
    >
      <div>
        <div>

          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1.5rem",
            marginTop: "1.5rem",
          }}>
            {/* Search field for the table */}
            <Input.Search
              placeholder="Aufgabe oder Artikel suchen"
              enterButton
              size="large"
              onSearch={selectedProject.articleSetIsLarge === true ? handleLargeDatasetSearch : handleSearch}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "1.5rem",
          }}>
            <Space
              size="large"
            >
              <Button type="primary" disabled onClick={handleNewTask}>Neue Aufgabe oder Artikel erstellen</Button>
              <Button type="dashed" onClick={props.onCancel}>Abbrechen</Button>
            </Space>
          </div>

        </div>
        <div>
          <Table
            columns={[
              {
                title: "Bild",
                dataIndex: "uniqueArticleNumber",
                key: "id",
                render: (ident, record) => (<ImageCell record={record} ident={ident} fs_checkIfArticleImageExists={fs_checkIfArticleImageExists} fs_checkAndGetArticleImage={fs_checkAndGetArticleImage} />)
              },
              {
                title: "Artikel / Aufgabe",
                dataIndex: "title",
                key: "id",
              },
              {
                title: "Beschreibung",
                dataIndex: "description",
                key: "id",
              },
              {
                title: "Globale Artikelnummer (HPM)",
                dataIndex: "uniqueArticleNumber",
                key: "id",
              },
              {
                title: "Abrechnungsstatus",
                dataIndex: "status_description",
                key: "id",
              },
              {
                title: "Aktionen",
                key: "id",
                render: (task) => (
                  <div>
                    <Button onClick={() => handleSelectTask(task)}>Auswählen</Button>
                  </div>
                ),
              },
            ]}
            dataSource={filteredData}
          />
        </div>
      </div>
    </Modal>
  )
};


function ImageCell({ record, ident, fs_checkIfArticleImageExists, fs_checkAndGetArticleImage }) {
  const [imageLoading, setImageLoading] = useState(true);
  const [src, setSrc] = useState("https://placehold.co/800x600?text=Kein+Bild\nvorhanden");

  useEffect(() => {
    const loadImage = async () => {
      console.debug("Loading image for:", record);
      setImageLoading(true);
      const exists = await fs_checkIfArticleImageExists(record);
      if (exists) {
        const imageSrc = await fs_checkAndGetArticleImage(record);
        setSrc(imageSrc);
      };
      setImageLoading(false);
    };

    loadImage();
  }, [record, fs_checkIfArticleImageExists, fs_checkAndGetArticleImage]);

  return (
    <div>
      {imageLoading ? <Spin /> : <Image src={src} alt={ident} style={{ width: 50, height: "auto" }} />}
    </div>
  );
}


export { SelectTaskModal };