import { Checkbox, Form, Input, InputNumber, Menu, Radio, Row, Select, Space, Switch, Tooltip } from "antd";
import { accounting } from 'accounting';
import React, { useState } from "react";
import { taskStatus } from "../../data/baseData";
import _ from "lodash";

const { Option } = Select;

const currencySelect = (
    <Select
        defaultValue="EUR"
        style={{
            width: 60,
        }}
    >
        <Option value="USD">$</Option>
        <Option value="EUR">€</Option>
        <Option value="GBP">£</Option>
    </Select>
);

const timeSelect = (
    <Select
        defaultValue="Min."
        style={{
            width: 60,
        }}
    >
        <Option value="Min.">Min.</Option>
        <Option value="Std.">Std.</Option>
    </Select>
);

const quantitySelect = (
    <Select
        defaultValue="Stück"
        style={{
            width: 60,
        }}
    >
        <Option value="Stück">Stück</Option>
        <Option value="Kg">Kg</Option>
        <Option value="Liter">Liter</Option>
    </Select>
);

function TaskForm(props) {

    const [status, setStatus] = useState({});
    const [multiple, setMultiple] = useState(false);
    const [showGuidlineDetails, setShowGuidlineDetails] = useState(false);
    const [showExtendedDetails, setShowExtendedDetails] = useState(false);
    const [quanitityUnit, setQuantityUnit] = useState("Stk");
    const [quantityUnitIsTime, setQuantityUnitIsTime] = useState(false);

    const handleChangeOfQuantityUnit = (value) => {
        setQuantityUnit(value);
        if (value === "h" || value === "min") {
            setQuantityUnitIsTime(true);
        } else {
            setQuantityUnitIsTime(false);
        }
    };

    return (
        <>
            <Form.Item
            label="Titel | Name | Artikelnummer bzw. Bezeichnung"
            name={"title"}
            rules={[{ required: true, message: "Es muss ein Titel angegeben werden, andernfalls können wir den Artikel nicht speichern!!" }]}
            >
                <Input placeholder="Titel der Leistung" />
            </Form.Item>
            <Form.Item
            name={"hasExtendedDetails"}
            >
                <Radio.Group onChange={(e) => { setShowExtendedDetails(e.target.value) }} value={showExtendedDetails}>
                    <Radio value={true}>Erweiterte Details anzeigen</Radio>
                    <Radio value={false}>Erweiterte Details ausblenden</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
            label="Artikelbild"
            name={"image"}
            rules={[{ required: false, message: "Es muss ein Bild angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            hidden={!showExtendedDetails}
            >
                <input type="file"
                    accept="image/*"
                    max={1}
                    onChange={(e) => { props.onImageChange(e) }}  // Handle image change
                />
            </Form.Item>

                <Form.Item
            label="Beschreibung"
            name={"description"}
            rules={[{ required: false, message: "Es muss eine Beschreibung angebgeben werden, anderfalls können wir den Artikel nicht speichern!" }]}
            hidden={!showExtendedDetails}
            >
                <Input placeholder="Kurze beschreibung der Leistung" />
            </Form.Item>
            <Form.Item
            label="Barcode"
            name={"barcode"}
            rules={[{ required: false, message: "Es muss ein Barcode angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            hidden={!showExtendedDetails}
            >
                <Input placeholder="Barcode" />
            </Form.Item>

            <Form.Item
            label="Barcode-Typ"
            name={"barcodeType"}
            rules={[{ required: false, message: "Es muss ein Barcode-Typ angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            hidden={!showExtendedDetails}
            >
                <Input placeholder="Barcode-Typ" />
            </Form.Item>

            <Form.Item
            label="Meingeneinheit"
            name={"quantityUnit"}
            rules={[{ required: true, message: "Es muss eine Mengeneinheit angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            // hidden={!showExtendedDetails}
            tooltip="Die Mengeneinheit gibt an, wie die Leistung abgerechnet wird. Bei Zeiteinheiten wird immer in Industriezeit gerechnet. 1 Stunde = 60 Minuten -> 1 Industriestunde = 100 Minuten. 1 Industrieminute = 1,67 Minuten"
            // Mögliche Einheiten: ["Stk", "m", "m2", "m3", "kg", "l", "h", "min", "kW", "kWh", "ml", "t"]
            >
                <Select
                    size={"medium"}
                    defaultValue={"Stk"}
                    onChange={handleChangeOfQuantityUnit}
                >
                    <Option value={"Stk"} key={1}>Stück/Vorgang/Einheit (Stk/pcs)</Option>
                    <Option value={"m"} key={2}>Meter (m)</Option>
                    <Option value={"m2"} key={3}>Quadratmeter (m²)</Option>
                    <Option value={"m3"} key={4}>Kubikmeter (m³)</Option>
                    <Option value={"kg"} key={5}>Kilogramm (kg)</Option>
                    <Option value={"l"} key={6}>Liter (l)</Option>
                    <Option value={"h"} key={7}>Stunde (h)</Option>
                    <Option value={"min"} key={8}>Minute (min)</Option>
                    <Option value={"kW"} key={9}>Kilowatt (kW)</Option>
                    <Option value={"kWh"} key={10}>Kilowattstunde (kWh)</Option>
                    <Option value={"ml"} key={11}>Milliliter (ml)</Option>
                    <Option value={"t"} key={12}>Tonne (t)</Option>
                </Select>
            </Form.Item>

            <Form.Item
            label="Mengentyp"
            name={"quantityType"}
            rules={[{ required: false, message: "Es muss ein Mengentyp angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            hidden={!showExtendedDetails}
            tooltip="Der Mengentyp definiert, wie die Leistung in der Arbechnung angezeigt wird. Wenn Sie z.B. 'Kilogramm pro Hub' angeben, wird dies auch so angezeigt anstatt von 'Kg'."
            >
                <Input placeholder="Mengentyp - Standard: Einheit" />
            </Form.Item>

            <Form.Item
            label="Änderung der Menge bei Abrechnung anbieten?"
            name={"quantityIsVariable"}
            tooltip="Beschreibt ob die Möglichkeit bei der erfassung von Artikeln besteht eine Dezimalzahl einzugeben. Z.B. 1,5 Stück. Wenn Sie 'Nein' auswählen, wird die immer nach dem Prinzip 'ein Vorgang hat eine Menge von 1' gerechnet. Wie z.B. bei N-ERGIE"
            >
                <Radio.Group value={showGuidlineDetails} defaultValue={false}>
                    <Radio value={true}>Ja</Radio>
                    <Radio value={false}>Nein</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
            label="Währung"
            name={"currency"}
            rules={[{ required: showExtendedDetails, message: "Es muss eine Währung angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            hidden={!showExtendedDetails}
            >
                <Select
                    size={"medium"}
                    style={{
                        width: 400,
                    }}
                    defaultValue={"EUR"}
                >
                    <Option value={"EUR"} key={1}>
                        EUR €
                    </Option>
                    <Option value={"USD"} key={2}>
                        USD $
                    </Option>
                    <Option value={"GBP"} key={3}>
                        GBP £
                    </Option>
                </Select>
            </Form.Item>
            
            <Form.Item
            label={quantityUnitIsTime ? "Abrechnungsbetrag/Preis pro Zeiteinheit" : "Abrechnungsbetrag/Preis pro Einheit"}
            name={"price"}
            rules={[{ required: true, message: "Ohne Betrag wird es schwierig etwas Abzurechnen... Wenn es Kostenlos ist, dann trage eine '0' ein." }]}
            
            >
                <InputNumber placeholder="Preis/Peis"
                    addonAfter="EUR/USD"
                    decimalSeparator=","
                    type="decimal"
                />
            </Form.Item>

            <Form.Item
            label="Mengenfaktor"
            name={"quantity"}
            rules={[{ required: true, message: "Es muss eine Menge angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            tooltip="Gibt an wie viele Artikel von dieser Leistung in einem Datensatz angegeben werden. Die Menge ist eigentlich immer 1. In seltenen Fällen kann dies hier angepasst werden. Wird z.B. 0,5 angegeben so wird der Preis am Ende halbiert. Wird 2 angegeben, so wird der Preis verdoppelt etc."
            >
                <InputNumber placeholder="Menge" addonBefore="multiplikator" defaultValue={1.00}
                    decimalSeparator=","
                    type="decimal"
                />
            </Form.Item>

            <Form.Item
            name="hasGuidlineDetails"
            >
                <Radio.Group onChange={(e) => { setShowGuidlineDetails(e.target.value) }} value={showGuidlineDetails} defaultValue={false}>
                    <Radio value={true}>Einstellungen zu Mengen- und Zeitvorgaben anzeigen</Radio>
                    <Radio value={false}>Einstellungen zu Mengen- und Zeitvorgaben ausblenden</Radio>
                </Radio.Group>
            </Form.Item>
            
                <Form.Item
            label="Vorgabezeit in pro Stück"
            name={"defaultTimePc"}
            required={[{ required: showGuidlineDetails, message: "Es muss eine Vorgabezeit angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            hidden={!showGuidlineDetails}
            >
                <InputNumber placeholder="Vorgabezeit" width={500} defaultValue={0}
                    decimalSeparator=","
                    type="decimal"
                />
            </Form.Item>
            <Form.Item
            label="Mengenvorgabe pro Zeiteinheit"
            name={"quantity_specification"}
            tooltip="Normalerweise wird eine Leistung mit einer Mengenvorgabe von 1 Stück erstellt. Wenn Sie eine andere Mengenvorgabe wünschen, können Sie diese hier angeben."
            hidden={!showGuidlineDetails}
            >
                <InputNumber placeholder="Mengenvorgabe" defaultValue={1}
                    decimalSeparator=","
                    type="decimal"
                />
            </Form.Item>
            <Form.Item
            label="Zeiteinheit"
            name={"defaultTimeUnitTimePc"}
            rules={[{ required: showGuidlineDetails, message: "Es muss eine Zeiteinheit angegeben werden, andernfalls können wir den Artikel nicht speichern!" }]}
            tooltip="Hier wird in Industriezeiten gerechnet. 1 Stunde = 60 Minuten. 1 Industriestunde = 100 Minuten. 1 Industrieminute = 1,666666666666667 Minuten"
            hidden={!showGuidlineDetails}
            >
                <Select
                    size={"medium"}
                    style={{
                        width: 400,
                    }}
                    defaultValue={"minutes"}
                >
                    <Option value={"minutes"} key={1}>
                        Minuten (Industrieminuten) / 100 Minuten = 1 Std. (60 Minuten)
                    </Option>
                    <Option value={"hours"} key={2}>
                        Stunden (Industriestunden) / 1 Stunde = 1 Std. (60 Minuten) / 100 Industrieminuten
                    </Option>
                </Select>
            </Form.Item>

            <Form.Item
            label="Status"
            name={"status"}
            rules={[{ required: true, message: "Sie müssen einen Status angeben!" }]}
            tooltip="Der Status gibt an, ob die Leistung aktiv oder inaktiv ist. Inaktive Leistungen werden bei KPI's nicht berücksichtigt. Sie können den Status jederzeit ändern, aber nicht rückwirkend."
            >
                <Select
                    size={"medium"}
                    value={status}
                    onChange={(value, option) => { setStatus(option.data); props.setStatus(option.data) }}
                    style={{
                        width: 400,
                    }}
                >
                    {!_.isEmpty(taskStatus) && taskStatus.map((task, index) => (
                        <Option value={task.value} key={index} data={task}>
                            {task.description}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
            label="In der Datenerfassungsmaske 'Mehrfach-erstellen' anbieten?"
            valuePropName="multiple"
            name={"multiple"}
            rules={[{ required: true, message: "Grundsätzlich muss mit dieser Funktion sehr vorsichtig umgegangen werden, es sollte dennoch 'Ja' oder 'Nein' ausgewählt sein!" }]}
            tooltip="Es wird eine Leistung erstellt, die beim Eintragen von Tätigkeiten mit einer benutzerdefinierten Anzahl mehrfach erstellt wird. z.B. ein Vorgang ohne Vorgangsnummer, der 100 mal erstellt werden soll. Die Funktion ist mit Vorsicht zu genießen!"
            >
                <Select
                    size={"medium"}
                    style={{
                        width: 400,
                    }}
                    value={multiple}
                    onChange={(value) => { setMultiple(value); props.setMultiple(value); console.log(value) }}
                    defaultValue={false}
                >
                    <Option value={true} key={1}>
                        Ja
                    </Option>
                    <Option value={false} key={2}>
                        Nein
                    </Option>
                </Select>
            </Form.Item>
        </>
    )
};

export { TaskForm };