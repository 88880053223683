import { Button, Input, Select, Space, Table } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TaskDetailsModal } from "./TaskDetailsModal";
import { useNavigate } from "react-router-dom";
import { route_readArticles } from "../../../routing/v3_Routes/v3_Routes";
import { displayErrorMessage } from "../../../utils/messageServices/messageService";
import TaskDialog from "../../global/dialogs/TaskDialog";
import { displayErrorNotification } from "../../../utils/messageServices/notificationService";
import { CloudUploadOutlined } from "@ant-design/icons";
import { TaskTableUpload } from "./TaskTableUpload";
import { fs_uploadArticleImage } from "../../../firebase/fs_defaultActions";

function TaskManagementPage() {

  const navigate = useNavigate();
  const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);
  const selectedProject = useSelector((state) => state.projectState.selectedProject);
  const articles = useSelector((state) => state.projectState.selectedProject.selectedProjectArticles);

  //Search Task
  const [searchValue, setSearchValue] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(articles);
  const [loadAll, setLoadAll] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [initialFetch, setInitialFetch] = React.useState(true);
  const [taskDialog, setTaskDialog] = React.useState(false);
  const [taskCsvUpload, setTaskCsvUpload] = React.useState(false);

  const handleSearch = (value) => {
    setSearchValue(value);
  }

  const handleLargeDatasetSearch = async () => {
    setLoading(true);
    await fetchTasks();
    setLoading(false);
  };

  const fetchTasks = async () => {
    //größer oder gleich 1 -->  x > 1
    if (selectedProject.__v === 1 || selectedProject.csvSchema) {
      if (selectedProject.articleSetIsLarge === true) {
        if (loadAll === true) {
          await route_readArticles(selectedProject._id, true);
        } else {
          await route_readArticles(selectedProject._id, loadAll, searchValue);
        }
      } else {
        await route_readArticles(selectedProject._id);
      }
    } else {
      displayErrorNotification("Diese Funktion ist nur für Projekte der Version 1 verfügbar. Sie verwenden derzeit die Version 0.");
    }
  };

  const preFetchTask = async () => {
    if (selectedProject.__v === 1 || selectedProject.csvSchema) {
      if (selectedProject.articleSetIsLarge === true) {
          await route_readArticles(selectedProject._id, false);
      } else {
        await route_readArticles(selectedProject._id);
      }
    } else {
      displayErrorNotification("Diese Funktion ist nur für Projekte der Version 1 verfügbar. Sie verwenden derzeit die Version 0.");
    }
  };

  useEffect(() => {
    let count = 0;
    if (count === 0) {
      preFetchTask();
      count++;
    };

    return () => {
      count = 0;
    };

  }, []);

  React.useEffect(() => {
    if (selectedProject.articleSetIsLarge === true && loadAll === false) {
      setFilteredData(articles);
    } else if (selectedProject.articleSetIsLarge === true && loadAll === true) {
      setFilteredData(_.filter(articles, (task) => {
        const { title, description, id, _id, uniqueArticleNumber } = task;
        const searchValueLower = searchValue.toLowerCase();
        return (
          title.toLowerCase().includes(searchValueLower) ||
          (description?.toLowerCase().includes(searchValueLower)) ||
          (uniqueArticleNumber?.includes(searchValueLower)) ||
          (id?.includes(searchValueLower)) ||
          _id.includes(searchValueLower)
        );
      }));
    } else {
      setFilteredData(_.filter(articles, (task) => {
        const { title, description, id, _id, uniqueArticleNumber } = task;
        const searchValueLower = searchValue.toLowerCase();
        return (
          title.toLowerCase().includes(searchValueLower) ||
          (description?.toLowerCase().includes(searchValueLower)) ||
          (uniqueArticleNumber?.includes(searchValueLower)) ||
          (id?.includes(searchValueLower)) ||
          _id.includes(searchValueLower)
        );
      }));
    }
    
  }, [searchValue, articles, loadAll]);


  const [selectedTask, setSelectedTask] = useState(null);
  const handleTaskDetailsModalOpen = (task) => {
    setSelectedTask(task);
    setOpenTaskDetailsModal(true);
  };

  const handleTaskDetailsModalClose = () => {
    setOpenTaskDetailsModal(false);
  };

  let v1View = null;

  useEffect(() => {
    const checkTaskVersion = () => {
      if (_.has(selectedProject, "taskVersion")) {
        if (selectedProject.taskVersion === 2) {
          navigate("/project/articles/management/v2");
        }
      }
    };

    checkTaskVersion();

  }, [selectedProject]);

  const handleCreatedTask = async (article, image = null) => {
    setTaskDialog(false);

    if (image) {
      await fs_uploadArticleImage(article, image);
    };

    if (selectedProject.articleSetIsLarge === false) {
      handleTaskDetailsModalOpen(article);
    } else {
      handleTaskDetailsModalOpen(article);
    }
  };


  v1View = (
    <div>
      <h1>Artikelverwaltung | {selectedProject.title}</h1>
      <div>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
          marginTop: "1.5rem",
        }}>
          {/* Search field for the table */}
          <Input.Search
            placeholder="Aufgabe oder Artikel suchen"
            enterButton
            size="large"
            onSearch={selectedProject.articleSetIsLarge === true ? 
             (value) => { handleLargeDatasetSearch(value) } : 
              (value) => { handleSearch(value) }
            }
            onChange={(e) => handleSearch(e.target.value)}
            loading={loading}
          />
          <Button style={{ marginLeft: 10 }} type="primary" size="large" onClick={() => {setTaskDialog(true)}}>Neuer Artikel</Button>
          <Select style={{ marginLeft: 10 }} defaultValue="Inaktiv" size="large" onChange={(value) => {
            if (value === "Alle") {
              setLoadAll(true);
            } else {
              setLoadAll(false);
            }
          }
          }>
            <Select.Option value="Alle">Alle Artikel laden</Select.Option>
            <Select.Option value="Inaktiv">Standard lademodus</Select.Option>
          </Select>
          <Button icon={<CloudUploadOutlined />} style={{ marginLeft: 10 }} type="primary" danger size="large" onClick={() => { setTaskCsvUpload(true) }}>Artikelliste importieren</Button>
        </div>
      </div>
      <div>
        <Table
          columns={[{
            title: 'Artikel / Aufgabe',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: 'Beschreibung',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: 'Abrechnungsbetrag in €',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => (
              <p>{record.price}</p>
            )
          },
          {
            title: 'Aktionen',
            key: 'action',
            render: (_, record) => (
              <Space size={"middle"}>
                <Button type="text" danger onClick={() => { handleTaskDetailsModalOpen(record) }}>Aufgabe / Artikel bearbeiten</Button>
              </Space>
            )
          }]}
          dataSource={filteredData}
          loading={loading}
        />
      </div>
      <TaskDetailsModal
        open={openTaskDetailsModal}
        onCancel={handleTaskDetailsModalClose}
        task={selectedTask}
      />
      <TaskDialog 
      open={taskDialog} 
      ProjectName={selectedProject.title} 
      Project={selectedProject} 
      ProjectId={selectedProject._id} 
      Action="create" 
      setOpen={() => { setTaskDialog(false) }}
      onCreated={(article, image = null) => { handleCreatedTask(article, image)}}
      />

          <TaskTableUpload
          Open={taskCsvUpload}
          OnCancel={() => { setTaskCsvUpload(false) }}
          ProjectID={selectedProject._id}
          />

    </div>
  );

          return v1View;
};


export { TaskManagementPage };