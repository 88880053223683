import { Avatar, Button, Col, Descriptions, List, Row, Space, Table, Tour, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { v4_getProjectById } from "../../../routing/v4_Routes/project.routes";
import { useNavigate, useParams } from "react-router-dom";
import { PlusCircleOutlined, DatabaseOutlined, InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { borderRadius } from "@mui/system";
import { v4_get_latest_project_data } from "../../../routing/v4_Routes/stream.routes";
import { render } from "@testing-library/react";
import { useDispatch } from "react-redux";
import { fetchCustomFields } from "../../../data/fetchData";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { updateCustomFields, updateSelectedproject } from "../../../redux/projectSlicer";
import em from '../../../assets/Kurzbild_Eingabemaske.png';
import de from '../../../assets/VorschauDateneingabe.png';
import { SingleDetailModal } from "../../modals/accounting/SingleDetail.modal";

export const TitleImage = (props) => {
    const project = props?.project || null;
    const containerStyle = {
      position: 'relative',
      width: '100%',
      height: '215px', // Passe die Höhe nach Bedarf an
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
    };
  
    const imageStyle = {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      filter: 'blur(8px)',
      transform: 'scale(1.1)', // Um sicherzustellen, dass das Bild den Container vollständig abdeckt, wenn es weichgezeichnet wird
      transition: 'filter 0.3s ease' // Transition für die Unschärfe
    };
  
    const overlayStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      textAlign: 'center',
    };
  
    return (
      <div style={containerStyle}>
        <img 
          src="https://images.unsplash.com/photo-1711199694531-e982a79ea381?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
          alt="Title" 
          style={imageStyle}
        />
        <div style={overlayStyle}>
          {props?.overwrite ? <>
            {props?.children || null}
          </> : <>
          <Space direction="vertical">
            <Avatar 
            size={64} 
            src={project?.imageUrl || "https://via.placeholder.com/150"} 
                style={{
                    border: "2px solid white",
                }}
            />
            <Typography.Title level={2} style={{ color: "white"}}>
              {props?.title ? props?.title || "Titel nicht gefunden" : project?.title || "Titel nicht gefunden" }
            </Typography.Title>
            {props?.children || null}
          </Space>
          </>}
        </div>
      </div>
    );
  };

  const ProjectDetailContainer = ({ project }) => {
    const { Item } = Descriptions;

    return (
        <Descriptions title="Projektinformationen" bordered column={3}>
        <Item label="Projektname">{project?.title || "Projekt nicht gefunden"}</Item>
        <Item label="Kostenstelle">{project?.costCentre || "Nicht hinzugefügt"}</Item>
        <Item label="Letzte Stammdatenänderung">{new Date(project?.updatedAt).toLocaleDateString() || "Nicht hinzugefügt"}</Item>
        <Item label="Projektleitung">{project?.projectLead?.firstName + " " + project?.projectLead?.lastName  || "Nicht hinzugefügt"}
        <SingleDetailModal>
            <Descriptions title="Projektleitung" bordered column={1}>
                <Item label="E-Mail">{project?.projectLead?.email || "Nicht hinzugefügt"}</Item>
                <Item label="Telefon">{project?.projectLead?.mobileNumber || "Nicht hinzugefügt"}</Item>
                <Item label="Teams Anruf"><Typography.Link href={`callto:${project?.projectLead?.email}`}>Teams Anruf starten</Typography.Link></Item>
            </Descriptions>
        </SingleDetailModal>
        </Item>
        <Item label="Projektbetreuung">{project?.projectConsultant?.firstName + " " + project?.projectConsultant?.lastName || "Nicht hinzugefügt"}
        <SingleDetailModal>
            <Descriptions title="Projektbetreuung" bordered column={1}>
                <Item label="E-Mail">{project?.projectConsultant?.email || "Nicht hinzugefügt"}</Item>
                <Item label="Telefon">{project?.projectConsultant?.mobileNumber || "Nicht hinzugefügt"}</Item>
                <Item label="Teams Anruf"><Typography.Link href={`callto:${project?.projectConsultant?.email}`}>Teams Anruf starten</Typography.Link></Item>
            </Descriptions>
        </SingleDetailModal>
        </Item>
        
        </Descriptions>
    )
};

const LatestDataComponent = ({ latestData }) => {
    const data = latestData?.data || null;
    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
        },
        { 
            title: 'Vorgangstitel',
            dataIndex: 'title',  
            key: '_id',   
        },
        { 
            title: 'Vorgang', 
            dataIndex: 'task',
            key: '_id',
            render: (o) => <Typography.Text>{o?.title}</Typography.Text>,
        },
        {
            title: 'Menge (á Einheit)',
            dataIndex: 'task',
            key: '_id',
            render: (o) => <Typography.Text>{o?.quantity}</Typography.Text>,
        },
        {
            title: 'Datum',
            dataIndex: 'updatedAt',
            key: '_id',
            render: (o) => <Typography.Text>{new Date(o).toLocaleString()}</Typography.Text>,
        },
    ];
    return (
        <>
            <div>
                <Typography.Title level={5}>
                    Übersicht über die letzten 10 Buchungen
                </Typography.Title>
            </div>
            <Typography.Text level={5}>
                    (Alle Buchungen können über die Datenbank angezeigt werden)
                </Typography.Text>
            <Table
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            pagination={{
                pageSize: 5,
                position: ["bottomRight"]
            }}

            />
        </>
    )
};

export function PublicProjectPage() {
    const { _id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(null);
    const [latestData, setLatestData] = useState(null);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const [open, setOpen] = useState(false);
  
    const steps = [
      {
        title: 'Neuen Vorgang erstellen',
        description: 'Hier gelangt man zur Eingabemaske für das Projekt',
        cover: (
            <img
              alt="tour.png"
              src={em}
            />
          ),
        target: () => ref1.current,
      },
      {
        title: 'Datenbank öffnen',
        description: 'Hier kann direkt auf die Datenbank zugegriffen werden.',
        target: () => ref2.current,
        cover: (
            <img
              alt="tour.png"
              src={de}
            />
          ),
      },
      {
        title: 'Dokumentenablage',
        description: 'Hier finden Sie alle öffentlichen Dokumente. Die Funktion ist noch nicht verfügbar.',
        target: () => ref3.current,
      },
        {
            title: 'Projektinformationen und Details',
            description: 'Hier findets Du alle wichtigen Informationen zum Projekt.',
            target: () => ref4.current,
        },
        {
            title: 'Projektdaten',
            description: 'Hier werden immer die letzten 10 Buchungen angezeigt. Weitere Details findest Du in der Datenbank.',
            target: () => ref5.current,
        },
    ];

    const openDataEntryPage = async () => {
        dispatch(updateFetchingData(true));
        dispatch(updateSelectedproject(project));
        await fetchCustomFields(_id)
        .then((result) => {
            dispatch(updateCustomFields(result.data));
            if (project.dataMode === "csv") {
                navigate(`/projects/${project.projectId}/database/create/csv?viewData=${JSON.stringify({ projectId: project?.ProjectId, layout: "desktop", mongoId: _id}) } `)
            } else {
                navigate(`/projects/${project.projectId}/database/create?viewData=${JSON.stringify({ projectId: project?.ProjectId, layout: "desktop", mongoId: _id}) } `)
            }
        }).catch((err) => {
            console.log(err);
        })
        .finally(() => {
            dispatch(updateFetchingData(false));
        });

    };

    async function fetchProjectDetails() {
        setLoading(true);
        const data = await v4_getProjectById(_id);
        setProject(data);
        setLoading(false);
    };

    async function fetchLatestData() {
        setLoading(true);
        const data = await v4_get_latest_project_data(_id);
        setLatestData(data);
        setLoading(false);
    };

    // useEffect(() => {
    //     fetchProjectDetails(); 
    //     fetchLatestData();
 
    //     return () => {
    //         setProject(null);
    //         setLatestData(null);
    //     };
    // }, []);

    useEffect(() => {
        fetchProjectDetails(); 
        fetchLatestData();
 
        return () => {
            setProject(null);
            setLatestData(null);
        };
    }, [_id]);

    React.useEffect(() => {
        //Fensternamen setzen
        document.title = `${project?.title}`;
        return () => {
          document.title = "Assembly";
        };
      }, [project]);
    
    return (
        <>  
            
            <div style={{
                marginBottom: 20,
            }}>
            <TitleImage project={project} />
            </div>
            <div style={{
                alignContent: "justify", 
                textAlign: "justify",
                display: "block",
                marginBottom: 40
            }}>
                <Space>
                <Button type="primary" loading={loading} onClick={openDataEntryPage} icon={<PlusCircleOutlined />} ref={ref1}>
                        Neuen Vorgang erstellen
                    </Button>
                    <Button type="primary" loading={loading} onClick={() => { dispatch(updateSelectedproject(project)); navigate(`/projects/${project?.projectId}/db/${_id}`) }} icon={<DatabaseOutlined />} ref={ref2}>
                        Datenbank öffnen
                    </Button>
                    <Button type="primary" disabled onClick={() => {}} icon={<InfoCircleOutlined />} ref={ref3}>
                        Dokumente
                    </Button>
                    <Button type="dashed" onClick={() => setOpen(true)} icon={<QuestionCircleOutlined />}>
                        Bedienungshilfe
                    </Button>
                </Space>
            </div>
            <div ref={ref4} style={{
                alignContent: "justify",
                textAlign: "justify",
                display: "block",
            }}>
                <ProjectDetailContainer project={project} />
            </div>
            <div ref={ref5} style={{
                alignContent: "justify",
                textAlign: "justify",
                display: "block",
                marginTop: 40
            }}>
                <LatestDataComponent latestData={latestData} />
                <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
            </div>
        </>
    );
};

export default PublicProjectPage;