import { Button, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { addListenerTo, unsubscribeFrom } from "../../../listener/firebaseSnapshotListener";
import { CreateInvoiceModal } from "../../modals/accounting/CreateInvoiceModal";
import { useSelector } from "react-redux";
import { storage } from "../../../firebase";
import { getDownloadURL, listAll, ref, deleteObject, getMetadata } from "firebase/storage";



export function AccountingPage() {

    const [openInvoiceCreation, setOpenInvoiceCreation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);

    const project = useSelector(state => state.mainState.autoSelect_0);
    const user = useSelector(state => state.userState.mongoUser);

    const getDouments = async () => {
        // fetch documents
        const storageRef = ref(storage, `abrechnungen/${project.title}`);
        const files = await listAll(storageRef);
        // const fileNames = files.items.map((item) => {
        //     return item
        // });
        const fileData = await Promise.all(files.items.map(async (item) => {
            const metadata = await getMetadata(item);
            return metadata;
        }));
        setDocuments(fileData);
        // setDocuments(fileNames);
    };

    useEffect(() => {
        const listener = addListenerTo('accounting', async (_snapshot) => {
            getDouments();
        });
        listener();

        return () => {
            unsubscribeFrom(listener);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getDouments();

        return () => {
            setDocuments([]);
        };
        // eslint-disable-next-line
    }, []);

    const downloadFile = async (file) => {
        try {
            setLoading(true);
            console.log(file);
            const fileRef = ref(storage, file.fullPath)
            const url = await getDownloadURL(fileRef);
            window
                .open(url, '_blank')
                .focus();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        };
    };

    const deleteFile = async (file) => {
        try {
            setLoading(true);
            const fileRef = ref(storage, file.ref.fullPath);
            await deleteObject(fileRef);
        } catch (error) {
            console.log(error);
        } finally {
            await getDouments();
            setLoading(false);
        };
    }; 


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const now = new Date();
                const fileDate = new Date(record.timeCreated);
                //in last 24h
                if (now - fileDate < 86400000) {
                    return (
                        <Space>
                        <Tag color="green">Neu</Tag>
                            <Typography.Text>{text}</Typography.Text>
                        </Space>
                    )
                } else {
                    return (
                        <Space>
                            <Typography.Text>{text}</Typography.Text>
                        </Space>
                    )
                };

            }
        },
        {
            title: 'Erstellt am',
            dataIndex: 'timeCreated',
            key: 'timeCreated',
            render: (text) => (
                <Typography.Text>{new Date(text).toLocaleString()}</Typography.Text>
            ),
        },
        {
            title: "Größe",
            dataIndex: 'size',
            key: 'size',
            render: (text) => (
                //Size in MB
                <Typography.Text>{(text / 1024 / 1024).toFixed(2)} MB</Typography.Text>
            )
        },
        {
            title: 'Aktionen',
            dataIndex: 'actions',
            key: 'actions',
            render: (_text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => {
                        downloadFile(record);
                    }}
                    loading={loading}
                    >Ansehen/Herunterladen</Button>
                    <Button type="link" danger onClick={
                        () => {
                            deleteFile(record);
                        }
                    }
                    loading={loading}
                    >Löschen</Button>
                </Space>
            ),
        }
    ];

    return (
        <div>
            <div style={{
                marginBottom: "30px"
            }}>
                <Typography.Title level={2}>Abrechnungsverwaltung: {project?.title}</Typography.Title>
            </div>

            <div style={{
                alignContent: "left",
                textAlign: "left",

            }}>
                <Space>
                    <Button type="primary" onClick={
                        () => {
                            setOpenInvoiceCreation(true);
                        }
                    }>Abrechnung erstellen</Button>
                    <Button type="primary" disabled>Letzte Abrechnungen ansehen</Button>
                    <Button type="primary" disabled>Lieferschein/Abnahmebelg erstellen</Button>
                </Space>
            </div>


            <div style={{
                marginTop: "30px"
            }}>
                <Typography.Title level={5}>Abrechnungen</Typography.Title>
                {/* Tabelle? Was anzeigen? */}
                <Table dataSource={documents} columns={columns} loading={loading} />
            </div>



            <div>
                <CreateInvoiceModal
                    open={openInvoiceCreation}
                    onCancel={() => { setOpenInvoiceCreation(false) }}
                    Callback={() => { getDouments() }}
                    onLoading={(state) => { setLoading(state) }}
                    Project={project}
                    User={user}
                />
            </div>
        </div>
    )
};