//Diese seite bitte noch nicht löschen, da sie noch eventuell benötigt wird
import React from "react";

//Import Custom components
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, List, Tag, Typography } from "antd";
import _ from "lodash";
import { updateFetchingData } from "../../../redux/streamSlicer";
import { updateCustomFields, updateSelectedproject } from "../../../redux/projectSlicer";
import { useNavigate } from "react-router-dom";
import { fetchCustomFields } from "../../../data/fetchData";
import Search from "antd/es/input/Search";
import { motion } from "framer-motion";


function MyProjectsPage(props) {

    const projects = useSelector((state) => state.projectState.projects);
    const [filteredProjects, setFilteredProjects] = React.useState(projects);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOpenDetails = (item) => {
        dispatch(updateFetchingData(true));
        dispatch(updateSelectedproject(item));
        console.log(item.Project)
        navigate(`/v2/projects/project/${item?._id}`);
        dispatch(updateFetchingData(false));
    };

    const handleOpenDatabase = (item) => {
        dispatch(updateFetchingData(true));
        dispatch(updateSelectedproject(item));
        console.log(item)
        navigate(`/projects/${item.projectId}/db/${item._id}`);
        dispatch(updateFetchingData(false));
    };

    const handleOpenEntry = (item) => {
        dispatch(updateFetchingData(true));
        dispatch(updateSelectedproject(item));
        fetchCustomFields(item._id)
            .then((result) => {
                dispatch(updateCustomFields(result.data));
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => {
                navigate(`/projects/${item.projectId}/database/create${(item?.dataMode || "standard") === "standard" ? "" : "/csv"}?viewData=${JSON.stringify({ projectId: item.projectId, layout: "desktop", mongoId: item._id })} `)
                dispatch(updateFetchingData(false));
            });

    };

    useEffect(() => {
        // dispatch(updateFetchingData(true));
    }, []);

    React.useEffect(() => {
        //Fensternamen setzen
        document.title = `Meine Projekte`;
        return () => {
          document.title = "Assembly";
        };
      }, []);

    return (
        <>
            {/* {props.Arrow ? <HeaderWithBackButton backLink="/" /> : <></>} */}
            <div>
                <Alert message="Hinweis von dieser Seite: Die Suchfunktion ist noch nicht verfügbar." type="info" showIcon style={{ textAlign: "left" }} />
                {/* <Search
                    placeholder="Projekt suchen (Name, Beschreibung, Id, etc.)"
                    onSearch={value => {
                        setFilteredProjects(_.filter(projects, (project) => {
                            return project.title.toLowerCase().includes(value.toLowerCase());
                        }));
                    }}
                    size="large"
                    allowClear
                    disabled
                    style={{
                        marginTop: "10px"
                    }}
                /> */}
            </div>
            <div>
                <List
                    style={{
                        textAlign: "left",
                        alignContent: "justify",
                        display: "block",
                        paddingTop: "20px",
                    }}
                    itemLayout="vertical"
                    dataSource={projects}
                    pagination={{
                        pageSize: 4,
                    }}
                    grid={{ gutter: 16, column: 2 }}
                    renderItem={(item, i) => {
                        const delay = i * 0.1;
                        return (
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                    delay: delay,
                                    duration: 0.5,
                                    type: 'spring',
                                    stiffness: 100,
                                    damping: 10,
                                }}
                            >
                                <List.Item
                                    key={i}
                                >
                                    <Card
                                        title={<><Typography.Text>{item?.title}</Typography.Text>{(item?.dataMode || "standard") === "standard" && item.__v === 0 && <Typography.Text type="danger"> veraltete Version</Typography.Text>}</>}
                                        actions={[
                                            <Button onClick={() => handleOpenEntry(item)} type="icon" key="list-loadmore-edit" disabled={(item?.dataMode || "standard") === "standard" && item.__v === 0}>
                                                Neuen Vorgang erstellen
                                            </Button>,
                                            <Button onClick={() => handleOpenDatabase(item)} type="icon" key="list-loadmore-more">
                                                Datenbank öffnen
                                            </Button>,
                                            <Button onClick={() => handleOpenDetails(item)} type="icon" key="list-loadmore-more">
                                                Projekt Details öffnen
                                            </Button>
                                        ]}
                                    >
                                        <Card.Meta
                                            title={<Typography.Text>{item?.title}</Typography.Text>}
                                            description={item?.administrationNote}
                                            avatar={<img width={50} alt="logo" src={item?.imageUrl} />}
                                        />
                                        <div style={{
                                            marginTop: "10px"
                                        }}>
                                            <Tag color={item?.entryReleased || false ? "blue" : "orange"}>{item?.entryReleased || false ? "Freigegeben zur Datenerfassung" : "Nicht freigegeben zur Datenerfassung"}</Tag>
                                        </div>
                                    </Card>
                                </List.Item>
                            </motion.div>
                        )
                    }}
                />
            </div>
        </>
    );
};

export default MyProjectsPage;