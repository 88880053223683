import { Avatar, Space, Typography } from "antd";
import React from "react";
import { motion } from "framer-motion";

//../assembly-logo.png

const assemblyArray = [
    'A', 'S', 'S', 'E', 'M', 'B', 'L', 'Y'
];

export function LogoContainer() {

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [textVisible, setTextVisible] = React.useState(window.innerWidth > 768);

    React.useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
            setTextVisible(newWidth > 1080);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run effect only once

    return (
        <div className="logo-container" style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: 'transparent',
            paddingLeft: "20px",
            paddingRight: "20px",
        }}>
            {/* <img src={'../assembly-logo.png'} alt="Logo" style={{ width: "55px", marginTop: "0px" }} /> */}
            <Avatar size={40} src={'../assembly-logo.png'}
            style={{
                backgroundColor: "white"
            }}
             />
             <div style={{ marginRight: "10px" }}></div>
            {
             textVisible && assemblyArray.map((letter, index) => {
                    const delay = index * 0.1;
                    return (
                        <motion.div
                            initial={{ opacity: 0, y: 5 }}
                            animate={{ opacity: 1, y: 0, }}
                            transition={{
                                delay: delay,
                                duration: 0.1,
                                type: 'tween',
                                stiffness: 100,
                                damping: 10,
                            }}
                        >
                            <Typography.Title level={3} style={{ color: "black", marginTop: "11px" }}>
                                {letter}
                            </Typography.Title>
                        </motion.div>
                    )
                })
            }
        </div>
    );
};

/**
 * <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0, pathLength: 1, x: [0, 100, 0] }}
                            transition={{
                                delay: delay,
                                duration: 0.5,
                                type: 'tween',
                                stiffness: 100,
                                damping: 10,
                            }}
                        >
 */