import { Button, Card, Descriptions, Drawer, Flex, Image, List, Modal, Popconfirm, Space, Spin, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ValueConverter } from "../../../utils/valueConverters";
import { route_deleteArticle, route_updateArticle } from "../../../routing/v3_Routes/v3_Routes";
import { useUpdate } from "ahooks";
import { displayWarningMessage } from "../../../utils/messageServices/messageService";
import { fs_checkAndGetArticleImage, fs_uploadArticleImage } from "../../../firebase/fs_defaultActions";

const { Paragraph } = Typography;

function TaskDetailsModal(props) {
  const [task, setTask] = useState({ ...props.task });
  const [loading, setLoading] = useState(false);
  const update = useUpdate();
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [changeImageModalVisible, setChangeImageModalVisible] = useState(false);

  const handleEditChange = async (value, fieldName, expectedType) => {
    setLoading(true);
    console.log(value);
    console.log(fieldName);
    console.log(expectedType);

    const convertedValue = ValueConverter(value, expectedType);

    await updateArticle(convertedValue, fieldName, expectedType)
      .then((updatedArticle) => {
        console.log(updatedArticle);
        const updatedTask = { ...updatedArticle };
        console.log(updatedTask);
        setTask(updatedTask);
        update();
      })
      .finally(() => {
        setLoading(false);
      });

  };

  const doImageChecksAndSet = async () => {
    if (!props.task) return;
    setLoading(true);
    const img = await fs_checkAndGetArticleImage(props.task);
    if (img) {
      setImage(img);
    } else {
      setImage(null);
    };
    setLoading(false);
  };

  useEffect(() => {
    setTask(props.task);
    console.log(props.task);
    doImageChecksAndSet();

    return () => {
      setTask({});
      console.log("TaskDetailsModal unmounted");
    }
  }, [props.task]);


  const updateArticle = async (value, name, exType) => {
    const updatedArticle = await route_updateArticle(task._id, value, name, exType, false);
    return updatedArticle;
  };

  const deleteArticle = async () => {
    const deletionMessage = await route_deleteArticle(task._id, task.project);
    setLoading(false);
    displayWarningMessage("Artikel gelöscht", `Angenommen: ${deletionMessage.acknowledged}, Gelöscht: ${deletionMessage.deletedCount} - Referenzen gelöscht: ${deletionMessage.rmDetails.acknowledged}, Gelöscht: ${deletionMessage.rmDetails.modifiedCount}`);
    props.onCancel();
  };

  const t = _.has(task, "title")
  const id = t ? (_.has(task, "id") ? task.id : task._id) : "N/V";
  const uniqueArticleNumber = t ? (_.has(task, "uniqueArticleNumber") ? task.uniqueArticleNumber : "N/V") : "N/V";
  const currencyText = t ? (_.has(task, "currency") ? `in ${task.currency}` : "") : "N/V";
  const currency = t ? (_.has(task, "currency") ? task.currency : "EUR") : "EUR";
  const variableQuantity = t ? (_.has(task, "quantityIsVariable") ? task.quantityIsVariable : "N/V") : "N/V";
  const timeValue = t ? (_.has(task, "quantityIsTime") ? task.quantityIsTime : "N/V") : "N/V";

  return (
      <Drawer
        title={_.has(task, "title") ? <>Artikeldetails | {task.title}</> : <>Ein Fehler ist aufgetreten</>}
        open={props.open}
        onCancel={props.onCancel}
        onClose={props.onCancel}
        destroyOnClose
        footer={
          <div>
            <Space>
              <Button key="print" disabled>
                Artikelstammdaten drucken
              </Button>
              <Popconfirm title="Sind Sie sicher, dass Sie diesen Artikel und alle zugehörigen Referenzen löschen möchten?" onConfirm={() => { setLoading(true); deleteArticle() }} okText="Ja" okType="danger" cancelText="Nein">
                <Button key="delete" danger>
                  Artikel löschen
                </Button>
              </Popconfirm>
              <Button key="cancel" type="primary" onClick={props.onCancel} loading={loading}>
                Schließen
              </Button>
            </Space>
          </div>
        }
        width={"80%"}
      >
        <>
         
        </>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="ID">{id}</Descriptions.Item>
          <Descriptions.Item label="Bild">
          {image ? <div style={{marginBottom: 20}}>
          <Image src={image} alt="Article" style={{ width: "100px", height: "auto", maxWidth: "300px"  }}  />
          </div> : null}
          <Button type="link" onClick={() => {
            setChangeImageModalVisible(true);
            }}>Bild ändern / hinzufügen</Button>
          </Descriptions.Item>
          <Descriptions.Item label="Titel">
            <Paragraph editable={{ onChange: (value) => handleEditChange(value, "title", "string") }}>{t ? task.title : "NV"}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Artikelnummer">
            <Paragraph>{uniqueArticleNumber}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Beschreibung">
            <Paragraph editable={{ onChange: (value) => handleEditChange(value, "description", "string") }}>{t ? task.description : "NV"}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label={`Preis ${currencyText}`}>
            <Paragraph editable={{ onChange: (value) => handleEditChange(value, "price", "number") }} type="number">{t ? task.price : "NV"}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label={`Währung`}>
            <Paragraph type="number">{currency}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Standard Menge">
            <Paragraph editable={{ onChange: (value) => handleEditChange(value, "quantity", "number") }} type="number">{t ? task.quantity : "NV"}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Status">
            <Paragraph>{t && task.status}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Mehrfach erstellbar">
            <Paragraph>{t && (task.multiple ? "Ja" : "Nein")}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Barcode">
            <Paragraph disabled>{t ? task.barcode : ""}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Manuelle Mengenvariation">
            <Paragraph>{variableQuantity ? "Ja" : "Nein"}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Menge ist Zeitangabe">
            <Paragraph>{timeValue ? "Ja" : "Nein"}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Erstellt am">{t && task.createdAt}</Descriptions.Item>
          <Descriptions.Item label="Aktualisiert am">{t && task.updatedAt}</Descriptions.Item>
          <Descriptions.Item label="Erstellt von">System</Descriptions.Item>
          <Descriptions.Item label="Zuletzt aktualisiert von">Nicht verfügbar</Descriptions.Item>

        </Descriptions>

        <div style={{
          marginTop: 20, border: "solid", borderWidth: 1, borderColor: "#d9d9d9", borderRadius: 5, padding: 10
        }}>
          <Paragraph>
            <strong>Artikel Änderungshistorie</strong>
          </Paragraph>
          <List
            size="small"
            dataSource={(t && task.history.length > 0) ? task.history.slice().reverse() : ["Keine Änderungen vorhanden"]}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  description={item}
                />
              </List.Item>
            )}
            pagination={{
              pageSize: 5,
            }}
          />
        </div>
        <Spin spinning={loading} fullscreen />
        <Modal
          title={"Artikelbild ändern von: " + task?.uniqueArticleNumber || ""}
          open={changeImageModalVisible}
          onCancel={() => { setChangeImageModalVisible(false); setImageFile(null); }}
          footer={[
            <Button type="primary" loading={loading} onClick={async () => {
              const url = await fs_uploadArticleImage(props.task, imageFile);
              if (url) {
                setImage(url);
              };
              setChangeImageModalVisible(false);
              setImageFile(null);
              update();
            }}>Hochladen</Button>,
            <Button onClick={() => { setChangeImageModalVisible(false); setImageFile(null); }}>Abbrechen</Button>
          ]}
          >
            <Space direction="vertical">
            <input type="file" accept="image/*" onChange={(e) => {
              setImageFile(e.target.files[0]);
            }} />
            {imageFile && <div>
              <Space>
              Altes Bild:
              <Image src={image} alt="Current" style={{ width: "100px", height: "auto", maxWidth: "300px" }} />
                → Neues Bild: 
                <Image src={URL.createObjectURL(imageFile)} alt="Preview" style={{ width: "100px", height: "auto", maxWidth: "300px" }} />
              </Space>
            </div>}
            </Space>
          </Modal>
      </Drawer>
  );
};

export { TaskDetailsModal };