import { Alert, Button, DatePicker, Form, Input, Modal, Select, Switch, Typography } from "antd";
import React from "react";
import { invoicing_create } from "../../../routing/crudActions";
import SimpleBackdrop from "../../global/LoadingBackdrop";


export function CreateInvoiceModal(props) {

    const [loading, setLoading] = React.useState(false);

    const setLoadingState = (state) => {
        setLoading(state);
        props.onLoading(state);
    };

    const closeModal = () => {
        props.onCancel();
    };

    const createInvoice = async (values) => {
        console.log(values);
        await invoicing_create(props.Project._id, props.User._id, values);
        returnInvoice();
        setLoadingState(false);
        closeModal();
    };

    const returnInvoice = () => {
        props.Callback();
    };

    const onFinish = async (values) => {
        setLoadingState(true);
        await createInvoice(values);
    };

    return (
        <Modal
            title="Rechnung erstellen"
            open={props.open}
            onCancel={closeModal}
            footer={[
                <Button key="back" onClick={closeModal} loading={loading}>
                    Abbrechen
                </Button>,
            ]}
            width={"85%"}
        >
            <div>
                <div>
                    <Alert message="GoB Hinweis" description={<Typography.Paragraph>Abrechnungen aus Assembly stellen <strong>keine</strong> Rechnung im sinne der<strong> §§14, 14a UStG</strong> dar. Dennoch ist das system auf Basis der <strong>GoB nach §238 Abs. 1 HGB</strong> aufgebaut. Abrechnungen sind somit einzigartig, einmal fehlerhaft abgerechnet kann der Vorgang nicht Rückgängig gemacht werden. Um eine Abrechnungsvorschau zu erhalten, wählen Sie "Abrechnungsvorschau -&gt; Ja".</Typography.Paragraph>} type="info" showIcon />
                </div>


                <div>
                <Typography.Title level={4}>Abrechnungsformular</Typography.Title>
                <Typography.Paragraph>Eine Abrechnungsnummer wird selbstständig vom System vergeben. Sie wählen lediglich den Zeitraum.</Typography.Paragraph>
                    <Form
                        name="invoiceForm"
                        layout="vertical"
                        onFinish={onFinish}
                        >
                        <Form.Item
                            label="Zeitraum"
                            name="timeframe"
                            rules={[{ required: true, message: 'Bitte geben Sie einen Zeitraum an!' }]}
                        >
                            <DatePicker.RangePicker />
                        </Form.Item>

                        <Form.Item
                            label="Abzurechnendende Status"
                            name="status"
                            rules={[{ required: true, message: 'Bitte wählen Sie die abzurechnenden Status aus!' }]}
                        >
                            <Select mode="tags" style={{ width: '100%' }} placeholder="Bitte wählen Sie die abzurechnenden Status aus."
                                options={[
                                    { label: "Abgeschlossen", value: "completed" },
                                    { label: "Offen", value: "open" },
                                    { label: "In Bearbeitung", value: "in progress" },
                                    { label: "Wartend / on hold", value: "on hold" },
                                    { label: "Reklamation Kunde", value: "reclamation_customer" },
                                    { label: "Reklamation HPM", value: "reclamation_hpm" },
                                    { label: "Interne Prüfung", value: "internalCheck" },
                                    { label: "Storniert", value: "canceled" }
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Notizen"
                            name="invoiceNotes"
                            tooltip="Notizen zur Abrechnung, werden auf der Abrechnung vermerkt."
                        >
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item
                            label="Abrechnungsvorschau"
                            name="preview"
                        >
                        <Switch />
                        </Form.Item>

                        <Form.Item
                            label="Detailierter Bericht"
                            name="detailedReport"
                            tooltip="Erstellt einen detailierten Bericht zur Abrechnung. Dieser kann sehr viele Seiten haben. (1 Datensatz pro Seite)"
                        >
                        <Switch />
                        </Form.Item>

                        <Form.Item
                            label="Für Kunden"
                            name="customerView"
                            tooltip="Erstellt eine Abrechnung für den Kunden. Diese Abrechnung enthält keine internen Informationen."
                        >
                        <Switch />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Abrechnung erstellen
                            </Button>
                        </Form.Item>
                        </Form>
                </div>
            </div>
            <SimpleBackdrop open={loading} />
        </Modal>
    )
};