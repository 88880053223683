import axios from "axios"
import { userData } from "./sampleData";
import { statusColorHelper, statusHelper } from "../utils/helpers/statusHelper";
import { Tag } from "antd";
import _ from "lodash";

//Bundesländer
export const counties = [
    {name:"Bayern",id:"by"},
    {name:"Baden-Württemberg",id:"bw"},
    {name:"Berlin",id:"be"},
    {name:"Brandenburg",id:"bb"},
    {name:"Bremen",id:"hb"},
    {name:"Hamburg",id:"hh"},
    {name:"Hessen",id:"he"},
    {name:"Mecklenburg-Vorpommern",id:"mv"},
    {name:"Nidersachsen",id:"ni"},
    {name:"Saarland",id:"sl"},
    {name:"Sachsen",id:"sn"},
    {name:"Sachsen-Anhalt",id:"st"},
    {name:"Schleswig-Holstein",id:"sh"},
    {name:"Thüringen",id:"th"},
    {name:"Rheinland-Pfalz",id:"rp"},
    {name:"Nordrhein-Westfalen",id:"nrw"},
];

//Länder
export const countries = [
    { name:"Deutschland", id:"de" },
];

export const taskStatus = [
    { value: 'active', description: 'Aktiv (kann abgerechnet werden)'},
    { value: 'outstanding', description: 'Ausstehend (kann durchgeführt werden, darf noch nicht abgerechnet werden)'},
    { value: 'locked', description: 'Gesperrt (kann nicht durchgeführt und abgerechnet werden)'},
];

//Feiertage in Deutschland
export function getHolidays(region, year) {
    if (region !== "") {
        const url = `https://get.api-feiertage.de`
        axios.get(url, (req, res) => {
            console.log(req.body)
        });
    } else {
        const url = `https://get.api-feiertage.de`
        axios.get(url, (req, res) => {
            console.log(req.body)
        });
    };
};

export const BaseFields = [
{
    id: 1,
    fieldName: "Title",
    fieldNameId: "title",
    fieldType: "string",
    systemField: "text",
    fieldRequired: false,
    editable: false,
    fieldDefaultValue: "",
    fieldValidation:  ""
},
{
    id: 499,
    fieldName: "Erstellt von",
    fieldNameId: "createdBy",
    fieldType: "string",
    systemField: "text",
    editable: true,
    fieldRequired: true,
    fieldDefaultValue: userData._id,
    fieldValidation:  ""
},
{
    id: 500,
    fieldName: "Geändert von",
    fieldNameId: "modifiedBy",
    fieldType: "string",
    systemField: "text",
    fieldRequired: true,
    editable: true,
    fieldDefaultValue: userData._id,
    fieldValidation:  ""
},
{
    id: 501,
    fieldName: "Erstellt am",
    fieldNameId: "createdAt",
    fieldType: "date",
    systemField: "text",
    fieldRequired: true,
    editable: true,
    fieldDefaultValue: Date(),
    fieldValidation:  ""
},
{
    id: 502,
    fieldName: "Geändert am",
    fieldNameId: "modifiedAt",
    fieldType: "date",
    systemField: "text",
    fieldRequired: true,
    fieldDefaultValue: Date(),
    fieldValidation:  ""
},
]


export const hardCodedFields = [
    { field: 'id', headerName: 'ID', width: 120, editable: false },
    { field: 'title', headerName: 'Titel', width: 150, editable: false },
    { field: 'createdAt', headerName: 'Erstellt', width: 150, editable: false },
    { field: 'createdBy', headerName: 'Erstellt von Id', width: 150, editable: false },
    { field: 'modifiedAt', headerName: 'Geändert', width: 150, editable: false },
    { field: 'modifiedBy', headerName: 'Geändert von Id', width: 150, editable: false },
]

export const hardCodedFieldsAntd = [
    { dataIndex: 'id', title: 'Datenbank ID', width: 220 },
    { dataIndex: 'title', title: 'Vorgangstitel', width: 150, render: (t) => t ? _.truncate(t, { length: 80 }) : null },
    { dataIndex: "status", title: "Status", width: 175, render: (d) => <Tag color={statusColorHelper(d)}>{statusHelper(d)}</Tag> },
    { dataIndex: 'task', title: 'Leistung', width: 300, render: (t, o) => t ? t.title : null },
    { dataIndex: 'task', title: 'Menge (á Einheit)', width: 150, render: (t, o) => t ? Number(t?.quantity).toFixed(2) : null },
    { dataIndex: 'createdBy', title: 'Erstellt von', width: 300, render: (o) => o ? _.truncate(`${o.firstName} ${o.lastName}`, { length: 30}) : null },
    { dataIndex: 'task', title: 'Beschreibung', width: 300, render: (t, o) => t ? t.description : null },
    { dataIndex: 'createdAt', title: 'Erstellt', width: 180, render: (d) => d ? new Date(d).toLocaleDateString() : d, sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt), sortDirections: ['descend', 'ascend'] },
    { dataIndex: 'modifiedAt', title: 'Geändert', width: 180, render: (d) => d ? new Date(d).toLocaleDateString() : d, sorter: (a, b) => a.createdAt - b.createdAt, sortDirections: ['descend', 'ascend'] },
];


export const globalCostTypes = [
    { label: "Einzelkosten: Material", value: "Einzelkosten: Material" },
    { label: "Einzelkosten: Lohn", value: "Einzelkosten: Lohn" },
    { label: "Einzelkosten: Sonstiges", value: "Einzelkosten: Sonstiges" },
    { label: "Einzelkosten: Miete", value: "Einzelkosten: Miete" },
    { label: "Einzelkosten: Energie", value: "Einzelkosten: Energie" },
    { label: "Einzelkosten: Versicherungen", value: "Einzelkosten: Versicherungen" },
    { label: "Einzelkosten: Projektausstattung", value: "Einzelkosten: Projektausstattung" },
    { label: "Einzelkosten: Allgemeine Projektnebenkosten", value: "Einzelkosten: Allgemeine Projektnebenkosten" },
    { label: "Gemeinkosten: Material", value: "Gemeinkosten: Material" },
    { label: "Gemeinkosten: Lohn", value: "Gemeinkosten: Lohn" },
    { label: "Gemeinkosten: Sonstiges", value: "Gemeinkosten: Sonstiges" },
    { label: "Gemeinkosten: Miete", value: "Gemeinkosten: Miete" },
    { label: "Gemeinkosten: Energie", value: "Gemeinkosten: Energie" },
    { label: "Gemeinkosten: Versicherungen", value: "Gemeinkosten: Versicherungen" },
    { label: "Gemeinkosten: Projektausstattung", value: "Gemeinkosten: Projektausstattung" },
    { label: "Gemeinkosten: Allgemeine Projektnebenkosten", value: "Gemeinkosten: Allgemeine Projektnebenkosten" },
    { label: "Sonstiges (nicht zugeordnet)", value: "Sonstiges (nicht zugeordnet)" },
];